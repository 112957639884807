var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { size: "large", spinning: _vm.loading } }, [
    _c(
      "div",
      { ref: "home", staticClass: "home", on: { scroll: _vm.scrollHandler } },
      [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "a-steps",
              { attrs: { direction: "vertical" } },
              [
                _c(
                  "a-step",
                  {
                    class: [_vm.lang],
                    attrs: {
                      status: "finish",
                      title: "1." + _vm.$t("ChooseYourCustomerSearchMethod")
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 1,
                            expression: "step == 1"
                          }
                        ],
                        staticClass: "circle active process",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "w-search" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 2 || _vm.step == 3,
                            expression: "step == 2 || step == 3"
                          }
                        ],
                        staticClass: "circle active",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "w-search" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "a-step",
                  {
                    class: [_vm.lang],
                    attrs: {
                      status: _vm.step >= 2 ? "finish" : "wait",
                      title: "2." + _vm.$t("InputBasicInformation")
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 1,
                            expression: "step == 1"
                          }
                        ],
                        staticClass: "circle",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "gbase" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 2,
                            expression: "step == 2"
                          }
                        ],
                        staticClass: "circle active process",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "wbase" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 3,
                            expression: "step == 3"
                          }
                        ],
                        staticClass: "circle active",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "wbase" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "a-step",
                  {
                    class: [_vm.lang],
                    attrs: {
                      status: _vm.step > 2 ? "finish" : "wait",
                      title: "3." + _vm.$t("CampaignsSettings")
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 1 || _vm.step == 2,
                            expression: "step == 1 || step == 2"
                          }
                        ],
                        staticClass: "circle",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "gsetting" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == 3,
                            expression: "step == 3"
                          }
                        ],
                        staticClass: "circle active process",
                        attrs: { slot: "icon" },
                        slot: "icon"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "licon",
                          attrs: { iconClass: "wsetting" }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("Step1", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step == 1,
                  expression: "step == 1"
                }
              ],
              ref: "step1",
              attrs: {
                detailObj: _vm.detailObj,
                linkList: _vm.linkList,
                loaded: _vm.loaded
              },
              on: { callback: _vm.firstHandler }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step == 2,
                    expression: "step == 2"
                  }
                ],
                staticClass: "box2"
              },
              [
                _c(
                  "a-form",
                  { staticClass: "form", attrs: { form: _vm.form2 } },
                  [
                    _vm.selectedIndex == 2
                      ? [
                          _c(
                            "div",
                            { staticClass: "label-des", class: [_vm.lang] },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("EnterLinkedInSearchURL")))
                              ]),
                              _c(
                                "a-popover",
                                {
                                  staticStyle: { "z-index": "98" },
                                  attrs: {
                                    placement:
                                      _vm.lang == "AR"
                                        ? "bottomRight"
                                        : "bottomLeft",
                                    trigger: "click"
                                  },
                                  model: {
                                    value: _vm.visiable,
                                    callback: function($$v) {
                                      _vm.visiable = $$v
                                    },
                                    expression: "visiable"
                                  }
                                },
                                [
                                  _c("template", { slot: "content" }, [
                                    _c(
                                      "div",
                                      { staticClass: "popbox beauty-scroll" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "main-tit",
                                            class: [_vm.lang],
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("findUrl")) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ptit",
                                            class: [_vm.lang],
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "num",
                                                class: {
                                                  rtl:
                                                    _vm.lang == "AR"
                                                      ? true
                                                      : false
                                                }
                                              },
                                              [_vm._v("1")]
                                            ),
                                            _c("span", { class: [_vm.lang] }, [
                                              _vm._v(
                                                _vm._s(_vm.$t("loginAccount"))
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ptit",
                                            class: [_vm.lang],
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "num",
                                                class: {
                                                  rtl:
                                                    _vm.lang == "AR"
                                                      ? true
                                                      : false
                                                }
                                              },
                                              [_vm._v("2")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("Enterkeywords"))
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "search",
                                              staticStyle: {
                                                display: "inline"
                                              },
                                              attrs: {
                                                src: require("@/assets/img/search.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ptit ptit-3",
                                            class: [_vm.lang],
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "num",
                                                class: {
                                                  rtl:
                                                    _vm.lang == "AR"
                                                      ? true
                                                      : false
                                                }
                                              },
                                              [_vm._v("3")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "con-3" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("SelectThePeople")
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "txt-tip",
                                                    style: {
                                                      "text-align":
                                                        _vm.lang == "AR"
                                                          ? "right"
                                                          : "left"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "SelectThePeopleRed"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "420px",
                                                height: "auto",
                                                display: "inline"
                                              },
                                              attrs: {
                                                src: require("@/assets/img/example.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ptit",
                                            class: [_vm.lang],
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "num",
                                                class: {
                                                  rtl:
                                                    _vm.lang == "AR"
                                                      ? true
                                                      : false
                                                }
                                              },
                                              [_vm._v("4")]
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("copyURL")))
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              "text-align":
                                                _vm.lang == "AR"
                                                  ? "right"
                                                  : "left"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "search",
                                              staticStyle: {
                                                width: "532px",
                                                height: "auto"
                                              },
                                              attrs: {
                                                src: require("@/assets/img/likurl.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#0e756a",
                                      cursor: "pointer",
                                      "margin-left": "6px"
                                    },
                                    attrs: { type: "question-circle" }
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            { class: [_vm.lang] },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "search_url",
                                      {
                                        initialValue: _vm.search_url,
                                        rules: [
                                          // { required: true, message: $t('selectUrl') },
                                          {
                                            required: true,
                                            validator: async function(
                                              rule,
                                              value,
                                              callback
                                            ) {
                                              if (!value || !value.trim("")) {
                                                callback(_vm.$t("selectUrl"))
                                                return
                                              }
                                              // 添加逻辑校验是否存在调接口
                                              var res = await _vm.isHaveSearchUrl(
                                                value
                                              )
                                              if (!res.reslut) {
                                                callback(res.msg)
                                                return
                                              }
                                              callback()
                                            }
                                          }
                                        ],
                                        trigger: "blur"
                                      }
                                    ],
                                    expression:
                                      "[\n                  'search_url',\n                  {\n                    initialValue: search_url,\n                    rules: [\n                      // { required: true, message: $t('selectUrl') },\n                      {\n                        required: true,\n                        validator: async function (rule, value, callback) {\n                          if (!value || !value.trim('')) {\n                            callback($t('selectUrl'));\n                            return;\n                          }\n                          // 添加逻辑校验是否存在调接口\n                          let res = await isHaveSearchUrl(value);\n                          if (!res.reslut) {\n                            callback(res.msg);\n                            return;\n                          }\n                          callback();\n                        },\n                      },\n                    ],\n                    trigger: 'blur',\n                  },\n                ]"
                                  }
                                ],
                                staticClass: "input",
                                attrs: {
                                  size: "large",
                                  placeholder: _vm.$t("selectUrl")
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "label-des", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("CompanyInformation")) + " ")
                    ]),
                    _vm.knowlegeList[1] && _vm.knowlegeList[1].length > 0
                      ? _c(
                          "div",
                          { staticClass: "wrap-select", class: [_vm.lang] },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "select",
                                staticStyle: {
                                  width: "400px",
                                  "margin-bottom": "16px"
                                },
                                attrs: {
                                  placeholder: _vm.$t("selectHistory"),
                                  size: "large"
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.changeHandler(value, "company")
                                  }
                                }
                              },
                              _vm._l(_vm.knowlegeList[1], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    class: [_vm.lang],
                                    attrs: {
                                      value: item.content + "|||" + index
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "optionCont" }, [
                                      _vm._v(" " + _vm._s(item.show_name) + " ")
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "company",
                                {
                                  initialValue: _vm.formData.company.content,
                                  trigger: "blur",
                                  rules: [
                                    {
                                      required: true,
                                      validator: function(
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          !value ||
                                          !value.trim("") ||
                                          !_vm.formData.company.isClick
                                        ) {
                                          callback(_vm.$t("inputContent"))
                                          return
                                        }
                                        callback()
                                      }
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                'company',\n                {\n                  initialValue: formData.company.content,\n                  trigger: 'blur',\n                  rules: [\n                    {\n                      required: true,\n                      validator: function (rule, value, callback) {\n                        if (\n                          !value ||\n                          !value.trim('') ||\n                          !formData.company.isClick\n                        ) {\n                          callback($t('inputContent'));\n                          return;\n                        }\n                        callback();\n                      },\n                    },\n                  ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            clicked: _vm.formData.company.isClick,
                            noClicked: !_vm.formData.company.isClick
                          },
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            maxLength: _vm.maxLength,
                            "auto-size": { minRows: 3, maxRows: 7 },
                            placeholder: _vm.$t("inputContent")
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickInput("company")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "label-des", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("ProductInformation")) + " ")
                    ]),
                    _vm.knowlegeList[2] && _vm.knowlegeList[2].length > 0
                      ? _c(
                          "div",
                          { staticClass: "wrap-select", class: [_vm.lang] },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "select",
                                staticStyle: {
                                  width: "400px",
                                  "margin-bottom": "16px"
                                },
                                attrs: {
                                  placeholder: _vm.$t("selectHistory"),
                                  size: "large"
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.changeHandler(value, "product")
                                  }
                                }
                              },
                              _vm._l(_vm.knowlegeList[2], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    class: [_vm.lang],
                                    attrs: {
                                      value: item.content + "|||" + index
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "optionCont" }, [
                                      _vm._v(" " + _vm._s(item.show_name) + " ")
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "product",
                                {
                                  initialValue: _vm.formData.product.content,
                                  trigger: "blur",
                                  rules: [
                                    {
                                      required: true,
                                      validator: function(
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          !value ||
                                          !value.trim("") ||
                                          !_vm.formData.product.isClick
                                        ) {
                                          callback(_vm.$t("inputContent"))
                                          return
                                        }
                                        callback()
                                      }
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                'product',\n                {\n                  initialValue: formData.product.content,\n                  trigger: 'blur',\n                  rules: [\n                    {\n                      required: true,\n                      validator: function (rule, value, callback) {\n                        if (\n                          !value ||\n                          !value.trim('') ||\n                          !formData.product.isClick\n                        ) {\n                          callback($t('inputContent'));\n                          return;\n                        }\n                        callback();\n                      },\n                    },\n                  ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            clicked: _vm.formData.product.isClick,
                            noClicked: !_vm.formData.product.isClick
                          },
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            maxLength: _vm.maxLength,
                            "auto-size": { minRows: 3, maxRows: 7 },
                            placeholder: _vm.$t("inputContent")
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickInput("product")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "label-des", class: [_vm.lang] }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("CompetitorInformation")) + " "
                      )
                    ]),
                    _vm.knowlegeList[3] && _vm.knowlegeList[3].length > 0
                      ? _c(
                          "div",
                          { staticClass: "wrap-select", class: [_vm.lang] },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "select",
                                staticStyle: {
                                  width: "400px",
                                  "margin-bottom": "16px"
                                },
                                attrs: {
                                  placeholder: _vm.$t("selectHistory"),
                                  size: "large"
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.changeHandler(
                                      value,
                                      "competitive"
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.knowlegeList[3], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    class: [_vm.lang],
                                    attrs: {
                                      value: item.content + "|||" + index
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "optionCont" }, [
                                      _vm._v(" " + _vm._s(item.show_name) + " ")
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "competitive",
                                {
                                  initialValue:
                                    _vm.formData.competitive.content,
                                  trigger: "blur"
                                  // rules: [
                                  //   {
                                  //     required: true,
                                  //     validator: function (rule, value, callback) {
                                  //       if (
                                  //         !value ||
                                  //         !value.trim('') ||
                                  //         !formData.competitive.isClick
                                  //       ) {
                                  //         callback($t('inputContent'));
                                  //         return;
                                  //       }
                                  //       callback();
                                  //     },
                                  //   },
                                  // ],
                                }
                              ],
                              expression:
                                "[\n                'competitive',\n                {\n                  initialValue: formData.competitive.content,\n                  trigger: 'blur',\n                  // rules: [\n                  //   {\n                  //     required: true,\n                  //     validator: function (rule, value, callback) {\n                  //       if (\n                  //         !value ||\n                  //         !value.trim('') ||\n                  //         !formData.competitive.isClick\n                  //       ) {\n                  //         callback($t('inputContent'));\n                  //         return;\n                  //       }\n                  //       callback();\n                  //     },\n                  //   },\n                  // ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            clicked: _vm.formData.competitive.isClick,
                            noClicked: !_vm.formData.competitive.isClick
                          },
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            maxLength: _vm.maxLength,
                            "auto-size": { minRows: 3, maxRows: 7 },
                            placeholder: _vm.$t("inputContent")
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickInput("competitive")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "label-des", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("SuccessStories")) + " ")
                    ]),
                    _vm.knowlegeList[4] && _vm.knowlegeList[4].length > 0
                      ? _c(
                          "div",
                          { staticClass: "wrap-select", class: [_vm.lang] },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "select",
                                staticStyle: {
                                  width: "400px",
                                  "margin-bottom": "16px"
                                },
                                attrs: {
                                  placeholder: _vm.$t("selectHistory"),
                                  size: "large"
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.changeHandler(
                                      value,
                                      "customerExp"
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.knowlegeList[4], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    class: [_vm.lang],
                                    attrs: {
                                      value: item.content + "|||" + index
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "optionCont" }, [
                                      _vm._v(" " + _vm._s(item.show_name) + " ")
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "customerExp",
                                {
                                  initialValue:
                                    _vm.formData.customerExp.content,
                                  trigger: "blur",
                                  rules: [
                                    {
                                      required: true,
                                      validator: function(
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          !value ||
                                          !value.trim("") ||
                                          !_vm.formData.customerExp.isClick
                                        ) {
                                          callback(_vm.$t("inputContent"))
                                          return
                                        }
                                        callback()
                                      }
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                'customerExp',\n                {\n                  initialValue: formData.customerExp.content,\n                  trigger: 'blur',\n                  rules: [\n                    {\n                      required: true,\n                      validator: function (rule, value, callback) {\n                        if (\n                          !value ||\n                          !value.trim('') ||\n                          !formData.customerExp.isClick\n                        ) {\n                          callback($t('inputContent'));\n                          return;\n                        }\n                        callback();\n                      },\n                    },\n                  ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            clicked: _vm.formData.customerExp.isClick,
                            noClicked: !_vm.formData.customerExp.isClick
                          },
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            maxLength: _vm.maxLength,
                            "auto-size": { minRows: 3, maxRows: 7 },
                            placeholder: _vm.$t("inputContent")
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickInput("customerExp")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "label-des", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("PricingInformation")) + " ")
                    ]),
                    _vm.knowlegeList[5] && _vm.knowlegeList[5].length > 0
                      ? _c(
                          "div",
                          { staticClass: "wrap-select", class: [_vm.lang] },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "select",
                                staticStyle: {
                                  width: "400px",
                                  "margin-bottom": "16px"
                                },
                                attrs: {
                                  placeholder: _vm.$t("selectHistory"),
                                  size: "large"
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.changeHandler(value, "priceInfo")
                                  }
                                }
                              },
                              _vm._l(_vm.knowlegeList[5], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    class: [_vm.lang],
                                    attrs: {
                                      value: item.content + "|||" + index
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "optionCont" }, [
                                      _vm._v(" " + _vm._s(item.show_name) + " ")
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "priceInfo",
                                {
                                  initialValue: _vm.formData.priceInfo.content,
                                  trigger: "blur",
                                  rules: [
                                    {
                                      required: true,
                                      validator: function(
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          !value ||
                                          !value.trim("") ||
                                          !_vm.formData.priceInfo.isClick
                                        ) {
                                          callback(_vm.$t("inputContent"))
                                          return
                                        }
                                        callback()
                                      }
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                'priceInfo',\n                {\n                  initialValue: formData.priceInfo.content,\n                  trigger: 'blur',\n                  rules: [\n                    {\n                      required: true,\n                      validator: function (rule, value, callback) {\n                        if (\n                          !value ||\n                          !value.trim('') ||\n                          !formData.priceInfo.isClick\n                        ) {\n                          callback($t('inputContent'));\n                          return;\n                        }\n                        callback();\n                      },\n                    },\n                  ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            clicked: _vm.formData.priceInfo.isClick,
                            noClicked: !_vm.formData.priceInfo.isClick
                          },
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            "auto-size": { minRows: 3, maxRows: 7 },
                            placeholder: _vm.$t("inputContent")
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickInput("priceInfo")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "label-des", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("ContactInformation")) + " ")
                    ]),
                    _vm.knowlegeList[7] && _vm.knowlegeList[7].length > 0
                      ? _c(
                          "div",
                          { staticClass: "wrap-select", class: [_vm.lang] },
                          [
                            _c(
                              "a-select",
                              {
                                staticClass: "select",
                                staticStyle: {
                                  width: "400px",
                                  "margin-bottom": "16px"
                                },
                                attrs: {
                                  placeholder: _vm.$t("selectHistory"),
                                  size: "large"
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.changeHandler(
                                      value,
                                      "concatInfo"
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.knowlegeList[7], function(
                                item,
                                index
                              ) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: index,
                                    class: [_vm.lang],
                                    attrs: {
                                      value: item.content + "|||" + index
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "optionCont" }, [
                                      _vm._v(" " + _vm._s(item.show_name) + " ")
                                    ])
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "concatInfo",
                                {
                                  initialValue: _vm.formData.concatInfo.content,
                                  trigger: "blur",
                                  rules: [
                                    {
                                      required: true,
                                      validator: function(
                                        rule,
                                        value,
                                        callback
                                      ) {
                                        if (
                                          !value ||
                                          !value.trim("") ||
                                          !_vm.formData.concatInfo.isClick
                                        ) {
                                          callback(_vm.$t("inputContent"))
                                          return
                                        }
                                        callback()
                                      }
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                'concatInfo',\n                {\n                  initialValue: formData.concatInfo.content,\n                  trigger: 'blur',\n                  rules: [\n                    {\n                      required: true,\n                      validator: function (rule, value, callback) {\n                        if (\n                          !value ||\n                          !value.trim('') ||\n                          !formData.concatInfo.isClick\n                        ) {\n                          callback($t('inputContent'));\n                          return;\n                        }\n                        callback();\n                      },\n                    },\n                  ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          class: {
                            clicked: _vm.formData.concatInfo.isClick,
                            noClicked: !_vm.formData.concatInfo.isClick
                          },
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            "auto-size": { minRows: 3, maxRows: 7 },
                            placeholder: _vm.$t("inputContent")
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickInput("concatInfo")
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._l(_vm.formData.custList, function(k, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "div",
                            { staticClass: "label-des del-wrap" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !k.show,
                                      expression: "!k.show"
                                    }
                                  ],
                                  staticClass: "edit"
                                },
                                [
                                  _c("span", { class: [_vm.lang] }, [
                                    _vm._v(
                                      _vm._s(
                                        k.corpus_name
                                          ? k.corpus_name
                                          : _vm.$t("CustomContent")
                                      )
                                    )
                                  ]),
                                  _c("a-icon", {
                                    staticStyle: { color: "#0e756a" },
                                    attrs: { type: "edit" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editHandler(k, index)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                { key: index, class: [_vm.lang] },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: k.show,
                                        expression: "k.show"
                                      },
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "custList[" + index + "].corpus_name",
                                          {
                                            initialValue: k.corpus_name,
                                            trigger: "blur",
                                            rules: [
                                              {
                                                required: true,
                                                validator: function(
                                                  rule,
                                                  value,
                                                  callback
                                                ) {
                                                  if (
                                                    !value ||
                                                    !value.trim("")
                                                  ) {
                                                    callback(
                                                      _vm.$t("editTitle")
                                                    )
                                                    return
                                                  }
                                                  callback()
                                                }
                                              }
                                            ]
                                          }
                                        ],
                                        expression:
                                          "[\n                      `custList[${index}].corpus_name`,\n                      {\n                        initialValue: k.corpus_name,\n                        trigger: 'blur',\n                        rules: [\n                          {\n                            required: true,\n                            validator: function (rule, value, callback) {\n                              if (!value || !value.trim('')) {\n                                callback($t('editTitle'));\n                                return;\n                              }\n                              callback();\n                            },\n                          },\n                        ],\n                      },\n                    ]"
                                      }
                                    ],
                                    ref: "cus" + index,
                                    refInFor: true,
                                    staticClass: "input cusinput clicked",
                                    staticStyle: { width: "361px" },
                                    attrs: {
                                      size: "large",
                                      type: "input",
                                      index: index,
                                      placeholder: _vm.$t("editTitle")
                                    },
                                    on: { blur: _vm.saveInputCus }
                                  })
                                ],
                                1
                              ),
                              _c("a-icon", {
                                staticClass: "delItem",
                                attrs: { type: "delete" },
                                on: {
                                  click: function($event) {
                                    return _vm.delItem(index)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm.knowlegeList[6] && _vm.knowlegeList[6].length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "wrap-select",
                                  class: [_vm.lang]
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      staticClass: "select",
                                      staticStyle: {
                                        width: "400px",
                                        "margin-bottom": "16px"
                                      },
                                      attrs: {
                                        placeholder: _vm.$t("selectHistory"),
                                        size: "large"
                                      },
                                      on: {
                                        change: function(value) {
                                          return _vm.changeHandlerCustomer(
                                            value,
                                            k
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.knowlegeList[6], function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          class: [_vm.lang],
                                          attrs: {
                                            value: item.content + "|||" + index
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "optionCont" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.show_name) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-form-item",
                            { key: index, class: [_vm.lang] },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "custList[" + index + "].content",
                                      {
                                        initialValue: k.content,
                                        trigger: "blur",
                                        rules: [
                                          {
                                            required: true,
                                            validator: function(
                                              rule,
                                              value,
                                              callback
                                            ) {
                                              if (!value || !value.trim("")) {
                                                callback(
                                                  _vm.$t("inputCustomContent")
                                                )
                                                return
                                              }
                                              callback()
                                            }
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                    `custList[${index}].content`,\n                    {\n                      initialValue: k.content,\n                      trigger: 'blur',\n                      rules: [\n                        {\n                          required: true,\n                          validator: function (rule, value, callback) {\n                            if (!value || !value.trim('')) {\n                              callback($t('inputCustomContent'));\n                              return;\n                            }\n                            callback();\n                          },\n                        },\n                      ],\n                    },\n                  ]"
                                  }
                                ],
                                staticClass: "input clicked",
                                staticStyle: { "overflow-y": "auto" },
                                attrs: {
                                  size: "large",
                                  type: "textarea",
                                  "auto-size": { minRows: 3, maxRows: 7 },
                                  placeholder: _vm.$t("inputCustomContent")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "addItem",
                        class: [_vm.lang],
                        on: { click: _vm.addItem }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("addMore")) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wrap-btn",
                        staticStyle: {
                          "margin-top": "40px",
                          "text-align": "center"
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            class: [_vm.lang],
                            staticStyle: { "margin-right": "20px" },
                            attrs: { size: "large" },
                            on: { click: _vm.backHandler }
                          },
                          [_vm._v(_vm._s(_vm.$t("back")))]
                        ),
                        _c(
                          "a-button",
                          {
                            class: [_vm.lang],
                            attrs: { size: "large", type: "primary" },
                            on: { click: _vm.nextStep }
                          },
                          [_vm._v(_vm._s(_vm.$t("NextStep")))]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step == 3,
                    expression: "step == 3"
                  }
                ],
                staticClass: "box3"
              },
              [
                _c(
                  "a-form",
                  { staticClass: "form", attrs: { form: _vm.form3 } },
                  [
                    _c("div", { staticClass: "mtit", class: [_vm.lang] }, [
                      _vm._v(_vm._s(_vm.$t("BriefSummary")))
                    ]),
                    _c("div", { staticClass: "stit", class: [_vm.lang] }, [
                      _vm._v(" " + _vm._s(_vm.$t("BriefSummaryIntro")) + " ")
                    ]),
                    _c(
                      "a-form-item",
                      { class: [_vm.lang] },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "short_description",
                                {
                                  initialValue: _vm.short_description,
                                  rules: [
                                    {
                                      required: true,
                                      message: _vm.$t("shortDes")
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n                'short_description',\n                {\n                  initialValue: short_description,\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('shortDes'),\n                    },\n                  ],\n                },\n              ]"
                            }
                          ],
                          staticClass: "input",
                          staticStyle: { "overflow-y": "auto" },
                          attrs: {
                            size: "large",
                            type: "textarea",
                            "auto-size": { minRows: 15, maxRows: 50 },
                            placeholder: _vm.$t("shortDes")
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "numbers",
                        style: {
                          "justify-content":
                            _vm.lang == "AR" ? "flex-end" : "flex-start"
                        }
                      },
                      [
                        _c("span", { staticClass: "text", class: [_vm.lang] }, [
                          _vm._v(_vm._s(_vm.$t("sendLimit")))
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "sub",
                            class: { green: _vm.num > 1 ? true : false },
                            on: { click: _vm.sub }
                          },
                          [_c("a-icon", { attrs: { type: "minus" } })],
                          1
                        ),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.numLg == 1 ? "0" + "" + _vm.num : _vm.num
                            )
                          )
                        ]),
                        _c(
                          "span",
                          { staticClass: "add green", on: { click: _vm.add } },
                          [_c("a-icon", { attrs: { type: "plus" } })],
                          1
                        ),
                        _c(
                          "a-popover",
                          {
                            staticStyle: { "z-index": "98" },
                            attrs: {
                              placement:
                                _vm.lang == "AR" ? "bottomRight" : "bottomLeft",
                              trigger: "click"
                            },
                            model: {
                              value: _vm.step3Visiable,
                              callback: function($$v) {
                                _vm.step3Visiable = $$v
                              },
                              expression: "step3Visiable"
                            }
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("div", { staticClass: "popbox" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "main-tit",
                                    class: [_vm.lang],
                                    style: {
                                      "text-align":
                                        _vm.lang == "AR" ? "right" : "left"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("step3TipTitle")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptit",
                                    class: [_vm.lang],
                                    style: {
                                      "text-align":
                                        _vm.lang == "AR" ? "right" : "left"
                                    }
                                  },
                                  [
                                    _c("span", { class: [_vm.lang] }, [
                                      _vm._v(_vm._s(_vm.$t("step3TipCon")))
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "txt-red",
                                        class: [_vm.lang]
                                      },
                                      [_vm._v(_vm._s(_vm.$t("step3TipCon1")))]
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "main-tit main-tit2",
                                    class: [_vm.lang],
                                    style: {
                                      "text-align":
                                        _vm.lang == "AR" ? "right" : "left",
                                      "margin-top": "16px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("step3TipTitle2")) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptit",
                                    class: [_vm.lang],
                                    style: {
                                      "text-align":
                                        _vm.lang == "AR" ? "right" : "left"
                                    }
                                  },
                                  [
                                    _c("span", { class: [_vm.lang] }, [
                                      _vm._v(_vm._s(_vm.$t("step3TipCon2")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn-box",
                                    style: {
                                      "text-align":
                                        _vm.lang == "AR" ? "right" : "left"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "btn-txt",
                                        class: [_vm.lang],
                                        on: { click: _vm.goStep3Link }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("bindLinkAccTipBtn"))
                                        )
                                      ]
                                    ),
                                    _c("span", { class: [_vm.lang] }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("bindLinkAccTipBtnTxt"))
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]),
                            _c("a-icon", {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#0e756a",
                                cursor: "pointer",
                                "margin-left": "6px"
                              },
                              attrs: { type: "question-circle" }
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "switchbox",
                        staticStyle: { display: "none" }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("a-switch", {
                              staticClass: "switch",
                              attrs: { size: "small" },
                              on: { change: _vm.switchchange1 },
                              model: {
                                value: _vm.like_post,
                                callback: function($$v) {
                                  _vm.like_post = $$v
                                },
                                expression: "like_post"
                              }
                            }),
                            _vm._v(_vm._s(_vm.$t("dianzan")) + " ")
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wrap-btn",
                        staticStyle: {
                          "margin-top": "40px",
                          "text-align": "center"
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            class: [_vm.lang],
                            staticStyle: { "margin-right": "20px" },
                            attrs: { size: "large" },
                            on: { click: _vm.backHandler }
                          },
                          [_vm._v(_vm._s(_vm.$t("back")))]
                        ),
                        _c(
                          "a-button",
                          {
                            class: [_vm.lang],
                            attrs: { size: "large", type: "primary" },
                            on: { click: _vm.nextStep }
                          },
                          [_vm._v(_vm._s(_vm.$t("Save")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }